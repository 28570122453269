import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { queryClient } from 'common/configs/queryClient';
import { pqApi } from 'common/services/http';
import { LoggerService } from 'common/services/logger';

export interface VisitFilters {
	question_tag_id?: number;
	show_current_tag_questions?: boolean;
}

export interface Visit {
	id: string;
	sex?: string;
	finished: boolean;
	patient_uid?: string;
	is_new_visit?: boolean;
	filters: VisitFilters;
}

export interface VisitUpdate {
	filters: VisitFilters;
}

export interface BackendTranslation {
	en: string;
	he: string;
}

export interface OutcomeDetails {
	title: BackendTranslation;
	subtype: string;
	explanation: BackendTranslation;
	internal_name: string;
	cms_id?: string;
}

export interface VisitOutcomesResponse {
	id: number;
	visit_id: number;
	visit_alert_id: number;
	outcome_id: number;
	type: string;
	weighted_priority: number;
	details: OutcomeDetails;
}

export interface FinishVisitRequest {
	checkNextQuestionnaireTags?: boolean;
}

export enum ReportEventType {
	VisitStarted = 'VISIT_STARTED',
	WelcomeNextClicked = 'WELCOME_NEXT_CLICKED',
	SymptomSearched = 'SYMPTOM_SEARCHED',
	EducationMaterialDisplayed = 'EDUCATION_MATERIAL_DISPLAYED'
}

const getVisitById = async (visitId: string) => {
	const { data } = await pqApi.get<Visit>(`v2/visits/${visitId}`);
	return data;
};

export const useGetVisitById = (visitId: string, enabled: boolean = false) => {
	return useQuery(['visit', visitId], () => getVisitById(visitId), {
		enabled,
		onError: (error: AxiosError) => LoggerService.error(`failed to get visit from server by id: ${visitId}`, { error })
	});
};

const updateVisit = async (visitId: string, visitUpdateData: VisitUpdate) => {
	const { data } = await pqApi.patch<Visit>(`v2/visits/${visitId}`, visitUpdateData);
	return data;
};

export const useUpdateVisit = (visitId: string) => {
	return useMutation(['visit', visitId], (visitUpdateData: VisitUpdate) => updateVisit(visitId, visitUpdateData), {
		onError: (error: AxiosError) => LoggerService.error(`failed to update visit. id: ${visitId}`, { error }),
		onSuccess: (data: Visit) => {
			queryClient.setQueryData(['visit', visitId], data);
		}
	});
};

const finishVisit = async (visitId: string, checkNextQuestionnaireTags: boolean = true) => {
	await pqApi.post(`visits/${visitId}/finish`, { check_next_questionnaire_tags: checkNextQuestionnaireTags });
};

export const useFinishVisit = (visitId: string) => {
	return useMutation(['finish-visit', visitId], (params: FinishVisitRequest) => finishVisit(visitId, params.checkNextQuestionnaireTags), {
		onError: (error: AxiosError) => LoggerService.error(`failed to finish visit ${visitId}`, { error })
	});
};

const getVisitSummary = async (visitId: string) => {
	const { data } = await pqApi.get<string>(`visits/${visitId}/medical_summary`);
	return data;
};

export const useGetVisitSummary = (visitId: string, enabled: boolean) => {
	return useQuery(['visit-summary', visitId], () => getVisitSummary(visitId), {
		enabled,
		onSuccess: () => LoggerService.info(`Visit summary was queried successfully`),
		onError: (error: AxiosError) => LoggerService.error(`failed to get visit summary for visit id ${visitId}`, { error })
	});
};

const getOutcomes = async (visitId: string) => {
	const { data } = await pqApi.get<VisitOutcomesResponse[]>(`visits/${visitId}/outcomes`);
	return data;
};

export const useGetAllOutcomes = (visitId: string) => {
	return useQuery(['visit-outcomes', visitId], () => getOutcomes(visitId), {
		onSuccess: () => LoggerService.info(`Outcomes was queried successfully`),
		onError: (error: AxiosError) => LoggerService.error(`failed to get outcomes for visit id ${visitId}`, { error }),
		enabled: false
	});
};

const addUserAction = async (visitId: string, userAction: string) => {
	const { data } = await pqApi.post(
		`v2/visits/${visitId}/user-action`,
		{ user_action: userAction },
		{ params: { 'skip-finish-validation': true } }
	);
	return data;
};

export const useAddUserAction = (visitId: string) => {
	return useMutation('add-user-action', (userAction: string) => addUserAction(visitId, userAction), {
		onError: (error: AxiosError) => LoggerService.error(`Failed to update user action in visit id: ${visitId}`, { error })
	});
};

const reportOutcomeDisplayed = async (visitId: string, outcomeType: string, outcomeSubtype?: string) => {
	const { data } = await pqApi.post(
		`v2/visits/${visitId}/report/outcome`,
		{ outcome_type: outcomeType, outcome_subtype: outcomeSubtype },
		{ params: { 'skip-finish-validation': true } }
	);
	return data;
};

export const useReportOutcomeDisplayed = (visitId: string) => {
	return useMutation(
		'report-outcome-displayed',
		(outcome: VisitOutcomesResponse) => reportOutcomeDisplayed(visitId, outcome.type, outcome.details.subtype),
		{
			onError: (error: AxiosError) => LoggerService.error(`Failed to report outcome in visit id: ${visitId}`, { error })
		}
	);
};

const reportEvent = async (visitId: string, event: ReportEventType, value?: string) => {
	const { data } = await pqApi.post(`v2/visits/${visitId}/report`, { event, value }, { params: { 'skip-finish-validation': true } });
	return data;
};

export const useReportVisitStarted = (visitId: string) => {
	return useMutation('report-visit-started', () => reportEvent(visitId, ReportEventType.VisitStarted), {
		onError: (error: AxiosError) => LoggerService.error(`Failed to report visit started for visit id: ${visitId}`, { error })
	});
};

export const useReportWelcomeNextClicked = () => {
	return useMutation('report-welcome-next-clicked', (visitId: string) => reportEvent(visitId, ReportEventType.WelcomeNextClicked), {
		onError: (error: AxiosError) => LoggerService.error(`Failed to report next clicked on the welcome page`, { error })
	});
};

export const useReportSymptomSearched = () => {
	return useMutation('report-symptom-searched', (params: { searchTerm: string, visitId: string }) => reportEvent(params.visitId, ReportEventType.SymptomSearched, params.searchTerm), {
		onError: (error: AxiosError) => LoggerService.error(`Failed to report symptom searched`, { error })
	});
};

export const useReportEducationMaterialDisplayed = (visitId: string) => {
	return useMutation(
		'report-education-material-displayed',
		(ddName: string) => reportEvent(visitId, ReportEventType.EducationMaterialDisplayed, ddName),
		{ onError: (error: AxiosError) => LoggerService.error(`Failed to report education material displayed`, { error }) }
	);
};
