import { Fragment, useEffect, useState } from 'react';
import { UseMutationResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Box, Link, Skeleton, Stack, styled, Typography } from '@mui/material';
import { AxiosError } from 'axios';

import {
	CMSBaseUIComponent,
	CMSOutcomeBulletList,
	CMSOutcomeButtonComponent,
	CMSOutcomeLinkComponent,
	CMSOutcomeLinkList,
	CMSOutcomeTitleComponent,
	CMSTextComponent,
	UIComponentType,
	useGetOutcomePageComponents
} from 'api/cms';
import {
	FinishVisitRequest,
	useAddUserAction,
	useFinishVisit,
	useGetVisitById,
	useReportOutcomeDisplayed,
	Visit,
	VisitOutcomesResponse
} from 'api/visit';
import { queryClient } from 'common/configs/queryClient';
import { i18nLanguageToBackendLanguage, Language } from 'common/constants';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import useQuestionTags from 'common/contexts/QuestionTagsContext';
import useIframeCommunication, { ExtraData, QuestionnaireEventsEnum, UserAction } from 'common/hooks/useIframeCommunication';
import useInitializeAnalytics from 'common/hooks/useInitializeAnalytics';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { AnalyticsService } from 'common/services/analytics';
import { LoggerService } from 'common/services/logger';
import { CheckFlowerIcon } from 'components/common/SvgIcons';
import { RoutesEnum } from 'components/router/Routes';
import OutcomeBulletList from './outcomeComponents/OutcomeBulletList';
import OutcomeButton from './outcomeComponents/OutcomeButton';
import OutcomeExplanation from './outcomeComponents/OutcomeExplanation';
import OutcomeLink from './outcomeComponents/OutcomeLink';
import OutcomeLinksList from './outcomeComponents/OutcomeLinksList';
import OutcomeText from './outcomeComponents/OutcomeText';
import OutcomeTitle from './outcomeComponents/OutcomeTitle';
import { getSubtypes, sortOutcomesSubtypesLayoutsByPosition } from './utils';

interface OutcomeProps {
	currentTag?: string;
	showVisitSummary: boolean;
	onShowVisitSummary: () => void;
	outcomes: VisitOutcomesResponse[] | undefined;
	visitId: string;
}

const OutcomeContainer = styled(Box)(({ theme }) => ({
	[theme.breakpoints.down('md')]: {
		backgroundColor: theme.palette.grey.light,
		borderRadius: '16px',
		padding: theme.spacing(3)
	}
}));

const SHOULD_CLOSE_QUESTIONNAIRE_INTERVAL_MS = 5000;

async function finishVisit(
	visit: Visit,
	isTheLastQuestionnaireTag: (currentQuestionTagId: number) => boolean,
	finishVisitMutation: UseMutationResult<void, AxiosError<unknown, any>, FinishVisitRequest, unknown>
) {
	const isVisitFinished = visit?.finished;
	const isLastTag = visit?.filters.question_tag_id && isTheLastQuestionnaireTag(visit?.filters.question_tag_id);
	if (!isVisitFinished && !isLastTag) {
		await finishVisitMutation.mutateAsync({ checkNextQuestionnaireTags: false });
	}
}

const Outcome: React.FC<OutcomeProps> = (props) => {
	const { t, i18n } = useTranslationWithGender();
	const { postEvent } = useIframeCommunication();
	const navigate = useNavigate();
	const featureFlags = useFeatureFlags();
	const finishVisitMutation = useFinishVisit(props.visitId);
	const updateUserActionMutation = useAddUserAction(props.visitId);
	const reportOutcomeDisplayed = useReportOutcomeDisplayed(props.visitId);
	const [isOutcomeClicked, setIsOutcomeClicked] = useState(false);
	const { isTheLastQuestionnaireTag } = useQuestionTags();
	const visit = useGetVisitById(props.visitId);
	const [shouldClosePage, setShouldClosePage] = useState(false);
	const [endedEventExtraData, setEndedEventExtraData] = useState<ExtraData | null>(null);

	useInitializeAnalytics(AnalyticsService.EVENTS.OutcomePage.Initialized);

	const backendLanguage = i18nLanguageToBackendLanguage[i18n.language as Language];

	const outcomeSubtypes = getSubtypes(props.outcomes);

	useEffect(() => {
		const reportOutcome = async (outcomes: VisitOutcomesResponse[]) => {
			await Promise.all(outcomes.map((outcome) => reportOutcomeDisplayed.mutateAsync(outcome)));
		};
		if (!!props.outcomes) {
			reportOutcome(props.outcomes);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.outcomes]);

	const { data: outcomeSubtypesLayouts, isLoading } = useGetOutcomePageComponents(
		{ outcome_subtypes: outcomeSubtypes, lang: i18n.language, questionnaire_section: props.currentTag, env: featureFlags.translationNamespace },
		!!props.outcomes
	);

	useEffect(() => {
		let interval: NodeJS.Timer | null = null;
		if (shouldClosePage && endedEventExtraData) {
			interval = setInterval(() => {
				LoggerService.warning('The questionnaire has ended but the page is still rendered');
				postEvent(QuestionnaireEventsEnum.QUESTIONNAIRE_ENDED, endedEventExtraData);
			}, SHOULD_CLOSE_QUESTIONNAIRE_INTERVAL_MS);
		}
		return () => {
			interval && clearInterval(interval);
		};
	}, [shouldClosePage, endedEventExtraData, postEvent]);

	const handleButtonClick = async (userAction: string, outcomeSubtype: string) => {
		setIsOutcomeClicked(true);
		await updateUserActionMutation.mutateAsync(userAction);
		if (userAction === UserAction.MoveToNextQuestionnaire) {
			AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.OutcomePage.MoveToNextQuestionnaireClicked);
			LoggerService.info('Continuing to next section of the questionnaire');
			queryClient.invalidateQueries(['visit-outcomes']);
			navigate(RoutesEnum.Questionnaire);
		} else {
			AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.OutcomePage.ButtonClicked);
			LoggerService.info(`${QuestionnaireEventsEnum.QUESTIONNAIRE_ENDED}, consultation: ${outcomeSubtype}, userAction: ${userAction}`);
			await finishVisit(visit?.data as Visit, isTheLastQuestionnaireTag, finishVisitMutation);
			LoggerService.info('Questionnaire ended');
			const eventExtraData = { consultation: outcomeSubtype, userAction };
			postEvent(QuestionnaireEventsEnum.QUESTIONNAIRE_ENDED, eventExtraData);
			setEndedEventExtraData(eventExtraData);
			setShouldClosePage(true);
		}
	};

	const handleLinkClick = (outcomeSubtype: string, userAction?: string) => {
		if (userAction) {
			handleButtonClick(userAction, outcomeSubtype);
		}
	};

	const getComponent = (component: CMSBaseUIComponent, outcomeSubtype: string): React.ReactElement | null => {
		if (!props.outcomes || props.outcomes.length === 0) {
			return null;
		}

		const relevantOutcomes = props.outcomes.filter((outcome) => outcome.details.subtype === outcomeSubtype);

		if (relevantOutcomes.length === 0) {
			return null;
		}

		const outcomeTitle = relevantOutcomes[0].details.title[backendLanguage];
		const outcomeExplanation = relevantOutcomes[0].details.explanation[backendLanguage];

		switch (component.ui_component_type) {
			case UIComponentType.OutcomeTitleComponent:
				const { outcome_prefix_title, outcome_suffix_title } = component as CMSOutcomeTitleComponent;
				return (
					<OutcomeTitle outcomeTitle={outcomeTitle} outcomePrefixTitle={outcome_prefix_title} outcomeSuffixTitle={outcome_suffix_title} />
				);

			case UIComponentType.Button:
				const { button_text, button_type, user_action_type } = component as CMSOutcomeButtonComponent;
				return (
					<OutcomeButton
						text={button_text}
						style={button_type}
						userActionType={user_action_type}
						isDisabled={isOutcomeClicked}
						onClick={() => handleButtonClick(user_action_type, outcomeSubtype)}
					/>
				);

			case UIComponentType.Text:
				const { text, font_style, text_alignment, text_color } = component as CMSTextComponent;
				return <OutcomeText text={text} fontStyle={font_style} textAlignment={text_alignment} textColor={text_color} />;

			case UIComponentType.OutcomeExplanationComponent:
				return <OutcomeExplanation outcomeExplanation={outcomeExplanation} />;

			case UIComponentType.OutcomeLinkComponent:
				const { text: linkText, user_action_type: userAction } = component as CMSOutcomeLinkComponent;
				return (
					<OutcomeLink linkText={linkText} onLinkClick={() => handleLinkClick(outcomeSubtype, userAction)} isDisabled={isOutcomeClicked} />
				);

			case UIComponentType.OutcomeBulletList:
				const { title } = component as CMSOutcomeBulletList;
				return <OutcomeBulletList language={backendLanguage} outcomes={relevantOutcomes} listTitle={title} />;

			case UIComponentType.OutcomeLinkList:
				const { title: linksListTitle } = component as CMSOutcomeLinkList;
				return <OutcomeLinksList language={backendLanguage} outcomes={relevantOutcomes} listTitle={linksListTitle} />;

			default:
				return null;
		}
	};

	const getComponents = () => {
		if (!outcomeSubtypesLayouts || outcomeSubtypesLayouts.length === 0) {
			return null;
		}

		let sortedOutcomeSubtypesLayouts = sortOutcomesSubtypesLayoutsByPosition(outcomeSubtypesLayouts);

		return (
			<Stack alignItems={{ xs: 'center', md: 'flex-start' }}>
				{sortedOutcomeSubtypesLayouts.map((outcomeSubtypesLayout) => {
					return outcomeSubtypesLayout.ui_components.map((component) => {
						return <Fragment key={component.key}>{getComponent(component, outcomeSubtypesLayout.outcome_subtype)}</Fragment>;
					});
				})}
			</Stack>
		);
	};

	return (
		<Stack spacing={2}>
			{backendLanguage === 'en' && <CheckFlowerIcon sx={{ width: 64, height: 64, fill: 'none' }} />}
			<Typography variant="h3" mb={1} data-testid="outcome-title">
				{t('outcomePageV2.title')}
			</Typography>
			<OutcomeContainer>
				{props.outcomes && !isLoading ? (
					getComponents()
				) : (
					<>
						<Skeleton animation="wave" variant="rectangular" sx={{ width: '87%', borderRadius: 'none', mb: 1.5 }} />
						<Skeleton animation="wave" variant="rectangular" sx={{ width: '60%', borderRadius: 'none' }} />
					</>
				)}
			</OutcomeContainer>
			{props.showVisitSummary && (
				<Link component="button" onClick={props.onShowVisitSummary} variant="primaryLink">
					{t('outcomePageV2.summaryLink')}
				</Link>
			)}
		</Stack>
	);
};

export default Outcome;
