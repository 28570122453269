import { Navigate, Outlet } from 'react-router-dom';

import useAuthenticationContext from 'common/contexts/AuthenticationContext';
import { RoutesEnum } from 'components/router/Routes';
import Loader from './Loader';

interface AuthenticatedPageProps {
	forceAuthentication: boolean;
}

const AuthenticatedPage: React.FC<AuthenticatedPageProps> = (props: AuthenticatedPageProps) => {
	const auth = useAuthenticationContext();

	if (auth.isLoading) {
		return <Loader />;
	}

	if (props.forceAuthentication && !auth.isAuthenticated) {
		return <Navigate to={RoutesEnum.Welcome} />;
	}

	return <Outlet />;
};

export default AuthenticatedPage;
