import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { pqApi } from 'common/services/http';
import { LoggerService } from 'common/services/logger';

export interface AdditionalData {
	firstName?: string;
	lastName?: string;
}

type Sex = 'M' | 'F' | 'ALL';

export interface CreatePatientDto {
	age: number;
	sex: Sex;
	additional_data_json?: AdditionalData;
	name?: number;
	policy_agree?: boolean;
	health_organization?: string;
	country?: string;
	phone_number?: string;
	external_source_id?: string;
	is_test_patient?: boolean;
}
export interface Patient {
	id: number;
	uid: string;
	sex: string;
	age: number;
	name: string | null;
	policy_agree: boolean;
	health_organization: string | null;
	phone_number: string | null;
	external_source_id: string | null;
	country: string | null;
	additional_data: AdditionalData | null;
}

export interface CreatePatientVisitDto {
	visit_limit?: string;
	visit_type?: string;
}

export interface CreatePatientVisitResponse {
	id: string;
	sex: string;
	patient_uid: string;
	is_new_visit: boolean;
}

const createPatientVisit = async (patientId: string, createVisitDto: CreatePatientVisitDto = {}) => {
	const { data } = await pqApi.post<CreatePatientVisitResponse>(`v2/patients/${patientId}/visits`, createVisitDto);
	return data;
};

const createPatient = async ({ age, sex, is_test_patient }: CreatePatientDto) => {
	const { data } = await pqApi.post<Patient>(`v2/patients`, { sex, age, is_test_patient });
	return data;
};

const getPatientById = async (patientId: string) => {
	const { data } = await pqApi.get<Patient>(`v2/patients/${patientId}`);
	return data;
};

export const useCreatePatient = () => {
	return useMutation('create-patient', (patientData: CreatePatientDto) => createPatient(patientData), {
		onError: (error: AxiosError) => LoggerService.error('failed to create patient', { error })
	});
};

export const useGetPatientById = (patientId: string) => {
	return useQuery(['get-patient', patientId], () => getPatientById(patientId), {
		onError: (error: AxiosError) => LoggerService.error(`failed to get patient from server by uid: ${patientId}`, { error })
	});
};

export const useCreatePatientVisit = () => {
	return useMutation('create-patient-visit', (patientId: string) => createPatientVisit(patientId), {
		onError: (error: AxiosError) => LoggerService.error(`failed to create visit to patient`, { error })
	});
};
