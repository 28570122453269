import React from 'react';
import { Box, Typography } from '@mui/material';

interface OutcomeTitleProps {
	outcomeTitle?: string;
	outcomePrefixTitle?: string;
	outcomeSuffixTitle?: string;
}

const OutcomeTitle: React.FC<OutcomeTitleProps> = (props) => {
	if (!props.outcomeTitle) {
		return null;
	}

	return (
		<Box alignSelf="start" marginBottom={3}>
			{props.outcomePrefixTitle && (
				<Typography variant="body3" display="inline">
					{`${props.outcomePrefixTitle} `}
				</Typography>
			)}
			<Typography variant="body1" display="inline">
				{props.outcomeTitle}
			</Typography>
			{props.outcomeSuffixTitle && (
				<Typography variant="body3" display="block">
					{props.outcomeSuffixTitle}
				</Typography>
			)}
		</Box>
	);
};

export default OutcomeTitle;
